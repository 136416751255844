export default function Policypage() {
    return (
        <>
            <div className="accordion" id="accordionPolicy">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <h4>Privacy Policy</h4>
                        </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionPolicy">
                        <div className="accordion-body">

                            <p>
                                <span>
                                    During the course of its business operations, HANG PHETCH THONG RATTANAMANEE COMPANY LIMITED. has
                                    always respected privacy rights and considered the potential impacts of
                                    personal data processing on customers, shareholders, employees, and other
                                    individuals associated with our company. Therefore, HANG PHETCH THONG RATTANAMANEE COMPANY LIMITED. is
                                    announcing its personal data protection policy to establish clear
                                    guidelines, mechanisms, and measures for data processing. This is to
                                    ensure that the aforementioned individuals receive legal protection that
                                    complies with international standards.
                                </span>
                            </p>
                            <p>
                                <span >
                                    <strong>1. Channels for Receiving Information</strong>
                                </span>
                            </p>
                            <ul className="wp-block-list">
                                <li style={{ listStyleType: "none" }}>
                                    <ul className="wp-block-list">
                                        {/* wp:list-item */}
                                        <p />
                                        <li>
                                            <span  >
                                                Personal data obtained directly from
                                                you: The company receives your personal data directly through one of
                                                the following methods:
                                            </span>
                                            <ol className="wp-block-list">
                                                <li style={{ listStyleType: "none" }}>
                                                    <ol className="wp-block-list">
                                                        <p />
                                                        <li>
                                                            <span  >
                                                                You contact or request information from the company.
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span  >
                                                                Service application forms, service request forms, additional
                                                                service request forms, or service change request forms.
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span  >
                                                                You register by creating an account through the company’s
                                                                website or application.
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span  >
                                                                You complete surveys or communicate with the company through
                                                                various channels, such as telephone, email.
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span  >
                                                                The company’s application, other social media platforms of
                                                                the company, or through the company’s employees.
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span  >
                                                                You perform transactions or provide information through the
                                                                company’s website, application, or devices.
                                                            </span>
                                                        </li>
                                                    </ol>
                                                </li>
                                            </ol>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul>
                                <li style={{ listStyleType: "none" }}>
                                    <ul className="wp-block-list">
                                        <li>
                                            <span  >
                                                Personal data obtained from external sources: The company may
                                                receive your personal data from external entities/individuals, such
                                                as product/service/website/application providers, public sources, or
                                                government agencies. In such cases, the company will inform you of
                                                the purpose and details of data processing, and may seek your
                                                consent for personal data processing again if:
                                            </span>
                                            <ol>
                                                <li style={{ listStyleType: "none" }}>
                                                    <ol className="wp-block-list">
                                                        <li>
                                                            <span  >
                                                                The processing has a significantly new and different
                                                                purpose.
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span  >
                                                                The processing may have severe impacts.
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span  >
                                                                The personal data to be processed is related to criminal
                                                                records.
                                                            </span>
                                                        </li>
                                                    </ol>
                                                </li>
                                            </ol>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <p>
                                <span  >
                                    <strong>
                                        2. Personal Data Collected, Used, and Disclosed by the Company :
                                        Personal data processed by the company includes:
                                    </strong>
                                </span>
                            </p>
                            <p>
                                <span  >
                                    A. Data that can directly or indirectly identify the data subject,
                                    including:
                                </span>
                            </p>
                            <ol className="wp-block-list">
                                <li style={{ listStyleType: "none" }}>
                                    <ol className="wp-block-list">
                                        <p />
                                        <li>
                                            <span  >
                                                Personal Information: Name, surname, gender, date of birth, age,
                                                information on various documents such as national ID card, passport,
                                                residence certificate, alien registration card, work permit, social
                                                security card, driver’s license, vehicle registration manual, or
                                                house registration. Signature, tax identification number, family
                                                member information, facial photos, educational background,
                                                occupation, status, membership, employment history, other benefits
                                                not related to compensation from work, insurance-related
                                                information.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                Contact Information: Home address, document delivery address, email
                                                address, home phone number, mobile phone number, fax number, names
                                                or accounts for using applications or digital channels such as Line,
                                                Google, Facebook, YouTube, Twitter, Whatsapp, or Wechat. Contact
                                                information provided to the company.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                Communication with the Company: Phone, electronic or digital
                                                channels, social media, CCTV data, and off-site services. This
                                                information may appear or be recorded in written form, voice
                                                recordings, transaction records, photos, or videos.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                Technical Information: Computer or Internet Protocol (IP) address,
                                                Media Access Control (MAC) address, device ID, connection logs,
                                                application interface (API) connections, cookies, types and versions
                                                of plugins, browser, operating system and platform, Internet or
                                                mobile network system, geographic location data, device settings,
                                                and other technical data from using the company’s platform,
                                                application, and operating system.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                Usage Information: Username, password, search data, viewing
                                                statistics, menus used, duration of use on the website, platform,
                                                application, timestamp of last click, favorites, Q&amp;A data,
                                                computer traffic data (log files), communication with the company.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                Behavioral Information: Information about personal interests or
                                                preferences, usage patterns, or services used.
                                            </span>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <p>
                                <span  >
                                    B. Sensitive Personal Data: For which the company must obtain your consent
                                    before collecting, including religion, criminal records, health
                                    information, or any other information as determined by the Personal Data
                                    Protection Committee.
                                </span>
                            </p>
                            <p>
                                <span  >
                                    <strong>
                                        3. Purposes for Collecting, Using, and Disclosing Your Personal Data
                                        <br />
                                    </strong>
                                </span>
                            </p>
                            <p>
                                <span  >
                                    The company will collect, use, and disclose your personal data based on
                                    the criteria set forth in the law, which includes:
                                </span>
                            </p>
                            <ol className="wp-block-list">
                                <li style={{ listStyleType: "none" }}>
                                    <ol className="wp-block-list">
                                        <p />
                                        <li>
                                            <span  >
                                                Contractual Necessity:&nbsp; To fulfill a contract or request you
                                                have made with the company.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                Legal Obligation:&nbsp; To comply with legal requirements that the
                                                company must follow.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                Legitimate Interests:&nbsp; For the legitimate interests of the
                                                company or another individual or legal entity.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                Necessity to Prevent Harm:&nbsp; To prevent harm to life, body, or
                                                health of individuals.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                Public Interest:&nbsp; For public interest purposes, public benefit
                                                missions, or the exercise of state rights.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                Consent:&nbsp; With your consent, in cases where the aforementioned
                                                criteria do not apply.
                                            </span>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <p>
                                <span  >For the following purposes:</span>
                            </p>
                            <ol>
                                <li style={{ listStyleType: "none" }}>
                                    <ol>
                                        <li>
                                            <span  >
                                                For communication, contact, or providing information related to the
                                                products or services you use or will use with the company.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                To fulfill the agreements specified in your requests or contracts
                                                made with the company, or related to those requests or contracts,
                                                such as document delivery, including to comply with contracts
                                                between the company and other necessary parties related to providing
                                                services to you.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                To manage the relationship between you and the company and create
                                                details or service history for future services to you.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                To manage customer data of legal entities or asset funds, which may
                                                include your personal data.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                To comply with laws and related regulations.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                To verify and confirm your identity according to the company’s Know
                                                Your Customer (KYC) process, including verifying your information
                                                and conducting checks as required by law and company policies.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                To comply with requests or recommendations from regulatory bodies
                                                overseeing the company, such as protecting customers from certain
                                                restrictions, preventing elderly individuals from performing certain
                                                transactions themselves, and damage control.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                For internal management and administration of the company, such as
                                                supervision, improvement, and auditing of internal operations.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                To manage and mitigate risks, such as
                                            </span>
                                            <ol>
                                                <li style={{ listStyleType: "none" }}>
                                                    <ul>
                                                        <li>
                                                            <span  >
                                                                Preventing, addressing, or reducing risks from illegal
                                                                activities that may affect you, the company’s customers,
                                                                personnel, and the company itself, using such information to
                                                                improve security systems for various channels, operational
                                                                systems, and IT security measures.
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span  >
                                                                For security purposes, such as recording images of visitors
                                                                or those conducting transactions with the company via CCTV
                                                                and requiring ID cards before entering the premises to
                                                                ensure internal security.
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span  >
                                                                To manage business-related risks of financial institutions,
                                                                such as credit risk, operational risk, legal risk, liquidity
                                                                risk, and market risk.
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <span  >
                                                To procure and offer products, services, and service options to you,
                                                including publicizing, contacting, notifying, granting, or
                                                presenting privileges, benefits, rewards, or information about the
                                                company’s products or services and those of group companies.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                To procure and offer products, services, and service options to you,
                                                including publicizing, contacting, notifying, granting, or
                                                presenting privileges, benefits, rewards, or information about the
                                                company’s or business partners’ products or services that may meet
                                                your needs, or to organize promotional activities, sweepstakes, or
                                                prize draws.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                To review service usage or verify transactions as per your or your
                                                contractual partners’ orders.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                To manage service provision and handle complaints or internal and
                                                external data transfers or handle customer complaints, compensation,
                                                or use information to adjust work processes.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                To conduct statistical analysis or research related to the company’s
                                                business and group or affiliated companies.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                To adjust strategies, maintain benefits, or evaluate the company’s
                                                performance or service provision.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                To evaluate, develop, and improve products or services, enforce
                                                company rights, and conduct market surveys, and to provide the
                                                analyzed data to you for financial planning or service usage with
                                                the company, or to business partners.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                To organize projects or promotional activities, meetings, seminars,
                                                recreational events, and site visits.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                To store data in cloud storage and other systems used by the
                                                company.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                To fulfill duties under conditions where the company is a
                                                contracting party or to enforce rights under the law or binding
                                                contracts.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                To connect or facilitate access to the company’s or third-party
                                                websites, applications, and platforms.
                                            </span>
                                            <ol className="wp-block-list"></ol>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <p>
                                <span  >
                                    Additionally, the collection, use, or disclosure of your personal data,
                                    including the transfer of your personal data abroad, is conducted
                                    according to these criteria.
                                </span>
                            </p>
                            <p>
                                <span  >
                                    <strong>
                                        4. Individuals or Entities That May Receive Disclosure of Your Personal
                                        Data from the Company
                                        <br />
                                    </strong>
                                </span>
                            </p>
                            <ol className="wp-block-list">
                                <li style={{ listStyleType: "none" }}>
                                    <ol className="wp-block-list">
                                        <p />
                                        <li>
                                            <span  >
                                                Business Partners: Such as platform co-service providers or
                                                individuals/entities whose names or logos appear in contracts,
                                                websites, or other service channels of the company.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                Service-related Individuals/Entities: Such as external service
                                                providers (outsourcing), contractors, or vendors supplying goods or
                                                services to the company, including infrastructure developers,
                                                internet network providers, telecommunications and communication
                                                service providers, technical infrastructure providers, electronic
                                                system or IT developers, logistics and warehouse providers, cloud
                                                service providers, data analysts, communication service providers,
                                                survey service providers, event organizers, identity verification
                                                and authentication service providers, audit and identity
                                                confirmation service providers, individuals or entities as required
                                                by law.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                Legal Entities/Authorities: The company may need to disclose your
                                                personal data to comply with laws, regulations, rules, or orders of
                                                government agencies, public authorities, regulatory bodies, or when
                                                the company believes such action is necessary to comply with the
                                                law, protect the rights of the company or others, ensure the safety
                                                of others, prevent, investigate, or address fraud, security, or
                                                other threats.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >Various Advisors</span>
                                        </li>
                                        <li>
                                            <span  >Other Related Individuals</span>
                                        </li>
                                        <li>
                                            <span  >
                                                Associations, Organizations, Clubs, and Various Entities
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                Websites and Social Media Platforms
                                            </span>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <p>
                                <span  >
                                    <strong>
                                        5. Retention of Your Personal Data and Duration of Retention
                                        <br />
                                    </strong>
                                </span>
                            </p>
                            <ol className="wp-block-list">
                                <li style={{ listStyleType: "none" }}>
                                    <ol className="wp-block-list">
                                        <p />
                                        <li>
                                            <span  >
                                                Retention of Your Personal Data: The company will ensure that data
                                                processing and retention procedures comply with legal requirements,
                                                taking into account the rights and potential impacts on individuals.
                                                The company will implement both technological and organizational
                                                measures to protect personal data and restrict unnecessary access.
                                                This aims to prevent and reduce the risk of data leaks or
                                                unauthorized use of your personal data. Security measures for
                                                personal data will be established in both paper and electronic
                                                formats to prevent loss, unauthorized access, use, alteration, or
                                                disclosure.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                Duration of Retention: The company will collect and retain your
                                                personal data for the purposes outlined in this document, as long as
                                                necessary under legal requirements, and up to a maximum of 10 years
                                                from the end of your relationship with the company, unless otherwise
                                                required by law or if technical limitations prevent deletion or
                                                destruction of the data.
                                            </span>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <p>
                                <span  >
                                    <strong>
                                        6. Transfer of Your Personal Data to Foreign Countries
                                        <br />
                                    </strong>
                                </span>
                            </p>
                            <p>
                                <span  >
                                    If it becomes necessary for the company to transfer your personal data to
                                    individuals located abroad, such as your or the company’s contractual
                                    partners, the company’s agents, foreign branches, affiliates,
                                    international agencies, or organizations, the destination country may have
                                    inadequate personal data protection standards as defined by law.
                                </span>
                            </p>
                            <p>
                                <span  >
                                    Nevertheless, in such cases, the company will implement appropriate
                                    processes to ensure that your transferred personal data remains secure.
                                </span>
                            </p>
                            <p>
                                <span  >
                                    <strong>
                                        7. Website Systems Used by the Company for Data Collection
                                        <br />
                                    </strong>
                                </span>
                            </p>
                            <p>
                                <span  >
                                    When you access the company’s website systems, certain data from your
                                    usage will be collected automatically for the purposes specified in this
                                    document. For example, data recorded or collected by cookies and similar
                                    technologies will be used for statistical analysis or other
                                    website-related activities to help provide you with a better user
                                    experience. This also aids in improving the efficiency and quality of the
                                    company’s website services.
                                </span>
                            </p>
                            <p>
                                <span  >
                                    <strong>8. Rights of Personal Data Owners</strong>
                                </span>
                            </p>
                            <ol className="wp-block-list">
                                <li style={{ listStyleType: "none" }}>
                                    <ol className="wp-block-list">
                                        <p />
                                        <li>
                                            <span  >
                                                <strong>Right to Access and Obtain Copies of Personal Data:</strong>
                                            </span>
                                            <br />
                                            <span  >
                                                You have the right to access and obtain copies of your personal data
                                                under the company’s responsibility, or request the company to
                                                disclose the source of personal data that you did not consent to.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                <strong>
                                                    Right to Receive, Send, or Transfer Personal Data to Another Data
                                                    Controller:
                                                </strong>
                                            </span>
                                            <br />
                                            <span  >
                                                You have the right to receive your personal data that you have
                                                provided to the company, based on your consent or contractual
                                                necessity, or as defined by the Personal Data Protection Committee.
                                                If the company has made such data available in a format readable and
                                                usable by automated tools, you have the right to (1) request the
                                                company to send or transfer the data to another data controller by
                                                automated means, and (2) receive your personal data that the company
                                                has sent or transferred directly to another data controller, unless
                                                technically infeasible.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                <strong>Right to Object:</strong>
                                            </span>
                                            <br />
                                            <span  >
                                                You have the right to object to the collection, use, or disclosure
                                                of your personal data by the company in the following cases:
                                            </span>
                                            <p />
                                            <ul>
                                                <li>
                                                    <span  >
                                                        When the company collects personal data due to necessity for
                                                        public interest or exercising state rights, or for legitimate
                                                        interests of the company or another person or legal entity.
                                                    </span>
                                                </li>
                                                <li>
                                                    <span  >
                                                        When the company collects, uses, or discloses your personal data
                                                        for direct marketing purposes.
                                                    </span>
                                                </li>
                                                <li>
                                                    <span  >
                                                        When the company collects, uses, or discloses your personal data
                                                        for scientific, historical, or statistical research purposes,
                                                        unless necessary for public interest tasks.
                                                    </span>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <span  >
                                                <strong>
                                                    Right to Request Deletion or Destruction of Personal Data:
                                                </strong>
                                            </span>
                                            <br />
                                            <span  >
                                                You have the right to request the company to delete or destroy your
                                                personal data, or make it anonymous, in cases where:
                                            </span>
                                            <p />
                                            <ul>
                                                <li>
                                                    <span  >
                                                        The personal data is no longer necessary for the purposes for
                                                        which the company collected it.
                                                    </span>
                                                </li>
                                                <li>
                                                    <span  >
                                                        You withdraw your consent, and the company has no legal grounds
                                                        to continue collecting, using, or disclosing the data.
                                                    </span>
                                                </li>
                                                <li>
                                                    <span  >
                                                        You object to the collection, use, or disclosure of your
                                                        personal data for public interest tasks or exercising state
                                                        rights, or for legitimate interests, and the company cannot deny
                                                        your objection.
                                                    </span>
                                                </li>
                                                <li>
                                                    <span  >
                                                        You object to the collection, use, or disclosure of personal
                                                        data for direct marketing purposes, or the personal data was
                                                        unlawfully collected, used, or disclosed.
                                                    </span>
                                                </li>
                                            </ul>
                                            <p>
                                                <span  >
                                                    However, this does not apply if the company needs to retain your
                                                    personal data to comply with the law, establish legal claims, or
                                                    protect the company’s legal rights.
                                                </span>
                                            </p>
                                        </li>
                                        <li>
                                            <span  >
                                                <strong>Right to Suspend Use of Personal Data:</strong>
                                            </span>
                                            <br />
                                            <span  >
                                                You have the right to request the suspension of the use of your
                                                personal data in cases where:
                                            </span>
                                            <p />
                                            <ul>
                                                <li>
                                                    <span  >
                                                        The company is verifying your request to correct inaccurate or
                                                        outdated personal data.
                                                    </span>
                                                </li>
                                                <li>
                                                    <span  >
                                                        The company has unlawfully collected, used, or disclosed your
                                                        personal data.
                                                    </span>
                                                </li>
                                                <li>
                                                    <span  >
                                                        The company no longer needs to collect, use, or disclose your
                                                        personal data for any purpose, but you request the company to
                                                        retain it for your benefit under the law or you request the
                                                        company to suspend the use of your personal data while the
                                                        company verifies or checks your objection to the personal data.
                                                    </span>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <span  >
                                                <strong>Right to Request Correction of Personal Data:</strong>
                                            </span>
                                            <br />
                                            <span  >
                                                You have the right to request the company to correct your personal
                                                data to be accurate, up-to-date, complete, and not misleading.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                <strong>Right to Withdraw Consent:</strong>
                                            </span>
                                            <br />
                                            <span  >
                                                You have the right to withdraw your consent to the company to
                                                collect, use, and disclose your personal data at any time.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                <strong>Right to Revoke Consent:</strong>
                                            </span>
                                            <br />
                                            <span  >
                                                You have the right to revoke your consent for personal data
                                                collected by the company before the Personal Data Protection Act
                                                B.E. 2562 came into force by submitting a revocation request to the
                                                main branch or unit of the company where you use or have used
                                                services.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                <strong>Right to File Complaints:</strong>
                                            </span>
                                            <br />
                                            <span  >
                                                You have the right to file complaints to the legal authorities in
                                                case the company, personal data processors, employees, or
                                                contractors of the company violate or fail to comply with personal
                                                data protection laws.
                                            </span>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <p>
                                <span  >
                                    If you wish to exercise any of the rights outlined in items 1 to 9 above,
                                    you may submit a request to the company through the company’s branch
                                    offices or other designated channels. Once the company receives your
                                    request, it will review your request according to the criteria and
                                    conditions specified by law and will fulfill your request and notify you
                                    of the outcome within 30 days from the date the company receives your
                                    completed request and supporting documents.
                                </span>
                            </p>
                            <p>
                                <span  >
                                    Exercising these rights might temporarily restrict your access to certain
                                    services provided by the company while your request is being reviewed or
                                    processed. The company will not charge you any fees for exercising these
                                    rights unless it deems that your request is excessive or without
                                    reasonable cause, in which case the company may charge a fee as specified
                                    in its published rates.
                                </span>
                            </p>
                            <p>
                                <span  >
                                    You may exercise your rights as a personal data owner starting from the
                                    effective date of the Personal Data Protection Act B.E. 2562 (2019).
                                </span>
                            </p>
                            <p>
                                <span  >
                                    <strong>
                                        9. Changes to This Privacy Notice
                                        <br />
                                    </strong>
                                </span>
                            </p>
                            <p>
                                <span  >
                                    As there is currently no clear legislation regarding the content of this
                                    privacy notice, this policy may be updated to better suit actual
                                    conditions and services provided. The company will announce any updates on
                                    this website. Therefore, we recommend that you regularly check to ensure
                                    that you understand the changes to the terms.
                                </span>
                            </p>
                            <p>
                                <span  >
                                    <strong>
                                        10. Contact Information
                                        <br />
                                    </strong>
                                </span>
                            </p>
                            <p>
                                <span  >
                                    If you wish to contact us or need additional information or explanations
                                    regarding the collection, use, and disclosure of your personal data,
                                    including exercising your rights as a personal data owner as described in
                                    this notice, you can contact the company at:{" "}
                                    <strong>
                                        37/1 Talay Luang Road, Bo Yang Subdistrict, Mueang Songkhla District, Songkhla Province 90000.
                                    </strong>
                                </span>
                            </p>
                            <p>
                                <span  >
                                    Additionally, you can contact the Data Protection Officer or the Data
                                    Protection Unit at the phone:{" "}
                                    <a rel="noreferrer">074 314 888</a> or at the head office
                                    address:
                                </span>
                                <br />
                                <span  >
                                    37/1 Talay Luang Road, Bo Yang Subdistrict, Mueang Songkhla District, Songkhla Province 90000.
                                </span>
                            </p>
                            <p>
                                <span  >
                                    <strong>
                                        11. Definitions and Scope
                                        <br />
                                    </strong>
                                </span>
                            </p>
                            <p>
                                <span  >
                                    To ensure clear understanding by all parties, the company provides the
                                    following definitions, which are referenced from the Personal Data
                                    Protection Act B.E. 2562 (2019):
                                </span>
                            </p>
                            <ol>
                                <li style={{ listStyleType: "none" }}>
                                    <ol>
                                        <li>
                                            <span  >
                                                <strong>Personal Data</strong>: Refers to information about an
                                                individual that can identify that person, whether directly or
                                                indirectly, excluding information about deceased individuals and
                                                legal entities.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                <strong>Sensitive Data</strong>: Refers to personal data that is
                                                sensitive, private, and has a high risk of being used for unfair
                                                discrimination.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                <strong>Processing of Personal Data</strong>: Refers to any
                                                operation performed on personal data, such as collection, transfer,
                                                use for the company’s target research, direct marketing, transfer of
                                                data, as well as deletion, destruction, or anonymization of the
                                                data.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                <strong>Deletion of Data</strong>: Refers to the removal,
                                                destruction, or any other action that renders the data
                                                irretrievable, at any time.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                <strong>Transfer of Data</strong>: Refers to the movement of
                                                personal data to another organization, including both domestic and
                                                international transfers.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                <strong>Data Breach</strong>: Refers to the unauthorized disclosure
                                                or security breach of personal data, which may affect rights or
                                                result in damage, loss, alteration, or unauthorized disclosure.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                <strong>Data Processor</strong>: Refers to a person responsible for
                                                processing data according to instructions or agreements made between
                                                the data processor and the data controller, including any agents of
                                                the data processor.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                <strong>Data Controller</strong>: Refers to a person with the
                                                authority to decide on the processing of personal data, including
                                                any agents of the data controller.
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                <strong>Company</strong>: Refers to HANG PHETCH THONG RATTANAMANEE COMPANY LIMITED, which may act
                                                as both a data processor and a data controller.
                                            </span>
                                        </li>
                                    </ol>
                                </li>
                            </ol>

                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <h4>นโยบายการคุ้มครองข้อมูลส่วนบุคคล</h4>
                        </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionPolicy">
                        <div className="accordion-body">

                            <p>
                                <span  >
                                    ตลอดระยะเวลาการประกอบกิจการที่ผ่านมา ทางบริษัท ห้างเพชรทองรัตนมณี จำกัด เคารพในสิทธิความเป็นส่วนตัว
                                    และคำนึงถึงผลกระทบจากการประมวลผลข้อมูลส่วนบุคคลที่อาจเกิดขึ้นกับลูกค้า
                                    ผู้ถือหุ้น พนักงาน รวมถึงบุคคลที่เกี่ยวข้องกับบริษัทของเราเป็นประการสำคัญ
                                    ทางบริษัท ห้างเพชรทองรัตนมณี จำกัด
                                    จึงขอประกาศนโยบายการคุ้มครองข้อมูลส่วนบุคคลของบริษัทฯ เพื่อกำหนดแนวทาง
                                    กลไก และมาตรการในการประมวลผลที่แน่นอนของบริษัทฯ
                                    และเพื่อให้แน่ใจว่าบุคคลดังที่กล่าวมาข้างต้นนี้
                                    จะได้รับความคุ้มครองที่ชอบด้วยกฎหมายและเป็นไปตามมาตรฐานสากล
                                </span>
                            </p>
                            <p>
                                <span  >
                                    <strong>
                                        1. ช่องทางการได้รับข้อมูล
                                        <br />
                                    </strong>
                                </span>
                            </p>
                            <ul className="wp-block-list">
                                <li style={{ listStyleType: "none" }}>
                                    <ul className="wp-block-list">
                                        <p />
                                        <li>
                                            <span  >
                                                ข้อมูลส่วนบุคคลที่บริษัทได้รับจากท่านโดยตรง :
                                                บริษัทได้รับข้อมูลส่วนบุคคลของท่านโดยตรงโดยวิธีการหนึ่งวิธีการใดในกรณีต่อไปนี้
                                                :
                                            </span>
                                            <ol className="wp-block-list">
                                                <li style={{ listStyleType: "none" }}>
                                                    <ol className="wp-block-list">
                                                        <p />
                                                        <li>
                                                            <span  >
                                                                ท่านติดต่อสอบถามหรือขอรับข้อมูลจากบริษัทฯ
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span  >
                                                                ใบสมัครใช้บริการ หรือแบบคำขอใช้บริการ
                                                                หรือแบบคำขอเพิ่มเติมหรือเปลี่ยนแปลงบริการ
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span  >
                                                                ท่านลงทะเบียนโดยการสร้างบัญชีผ่านเว็บไซต์หรือแอปพลิเคชัน
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span  >
                                                                ท่านได้ทำแบบสอบถามหรือติดต่อสื่อสารกับบริษัทผ่านช่องทางต่างๆ
                                                                อาทิทางโทรศัพท์ อีเมล
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span  >
                                                                แอปพลิเคชัน สื่อสังคมออนไลน์อื่นของบริษัท
                                                                หรือผ่านพนักงานของบริษัท
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span  >
                                                                ท่านทำรายการหรือให้ข้อมูลผ่านเว็บไซต์หรือแอปพลิเคชันหรืออุปกรณ์ของบริษัท
                                                            </span>
                                                        </li>
                                                    </ol>
                                                </li>
                                            </ol>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul>
                                <li style={{ listStyleType: "none" }}>
                                    <ul className="wp-block-list">
                                        <li>
                                            <span  >
                                                ข้อมูลส่วนบุคคลของท่านที่บริษัทได้รับมาจากบุคคลภายนอก: บริษัทฯ
                                                อาจได้รับข้อมูลส่วนบุคคลของท่านมาจากหน่วยงาน/บุคคลภายนอก
                                                หรือผู้ให้บริการผลิตภัณฑ์/บริการ/เว็บไซต์/แอปพลิเคชันอื่นๆ
                                                หรือแหล่งข้อมูลสาธารณะหรือหน่วยงานของรัฐเป็นต้น ในกรณีนี้
                                                บริษัทจะมีการแจ้งวัตถุประสงค์ รายละเอียดในการประมวลผลข้อมูล
                                                รวมถึงอาจมีการขอความยินยอมในการประมวลผลข้อมูลส่วนบุคคลกับท่านอีกครั้งหนึ่ง
                                                ในกรณีที่ :
                                            </span>
                                            <ol>
                                                <li style={{ listStyleType: "none" }}>
                                                    <ol className="wp-block-list">
                                                        <li>
                                                            <span  >
                                                                การประมวลผลนั้นมีวัตถุประสงค์ใหม่ที่แตกต่างไปจากเดิมมาก
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span  >
                                                                การประมวลผลนั้นอาจก่อให้เกิดผลกระทบรุนแรง
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span  >
                                                                ข้อมูลส่วนบุคคลที่จะทำการประมวลผลนั้น
                                                                เกี่ยวข้องกับประวัติอาชญากรรม
                                                            </span>
                                                        </li>
                                                    </ol>
                                                </li>
                                            </ol>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <p>
                                <span  >
                                    <strong>
                                        2. ข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคลที่บริษัทฯ จะเก็บรวบรวม ใช้
                                        และเปิดเผย : ข้อมูลส่วนบุคคลที่บริษัทจะทำการประมวลผล ประกอบด้วย
                                    </strong>
                                </span>
                            </p>
                            <p>
                                <span  >
                                    A.
                                    ข้อมูลที่สามารถระบุตัวตนของเจ้าของข้อมูลส่วนบุคคลได้ไม่ว่าทางตรงหรือทางอ้อม
                                    อันได้แก่
                                </span>
                            </p>
                            <ol className="wp-block-list">
                                <li style={{ listStyleType: "none" }}>
                                    <ol className="wp-block-list">
                                        <p />
                                        <li>
                                            <span  >
                                                ข้อมูลส่วนตัว ได้แก่ ชื่อ-นามสกุล เพศ วันเดือนปีเกิด อายุ
                                                ข้อมูลบนหรือในเอกสารต่างๆ เช่น บัตรประจำตัวประชาชน หนังสือเดินทาง
                                                ใบสำคัญถิ่นที่อยู่ ใบสำคัญประจำตัวคนต่างด้าว ใบอนุญาตทำงาน
                                                บัตรประกันสังคม ใบอนุญาตขับขี่รถยนต์ คู่มือจดทะเบียนรถยนต์
                                                หรือทะเบียนบ้าน ลายมือชื่อ เลขประจำตัวผู้เสียภาษีอากร
                                                ข้อมูลเกี่ยวกับสมาชิกในครอบครัว รูปถ่ายใบหน้า ประวัติการศึกษา อาชีพ
                                                สถานภาพ สมาชิกภาพ ประวัติการทำงาน
                                                ผลประโยชน์อื่นที่มิใช่ค่าตอบแทนจากการทำงาน
                                                ข้อมูลเกี่ยวข้องกับการทำประกัน
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                ข้อมูลเพื่อการติดต่อ ได้แก่ ที่อยู่ตามทะเบียนบ้าน
                                                ที่อยู่เพื่อการจัดส่งเอกสารต่างๆ ที่อยู่ไปรษณีย์อิเล็กทรอนิกส์
                                                (Email Address) หมายเลขโทรศัพท์บ้าน หมายเลขโทรศัพท์มือถือ
                                                หมายเลขโทรสาร
                                                ชื่อหรือบัญชีสำหรับการใช้งานผ่านแอปพลิเคชันหรือช่องทางดิจิทัล เช่น
                                                ไลน์ กูเกิล เฟซบุ๊ก ยูทูป หรือทวิตเตอร์ วอทส์แอป หรือวีแชท
                                                ข้อมูลผู้ติดต่อที่ให้แก่บริษัทฯ
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                ข้อมูลเกี่ยวกับการติดต่อกับบริษัท ได้แก่ โทรศัพท์
                                                ช่องทางอิเล็กทรอนิกส์หรือดิจิทัล สื่อสังคมออนไลน์
                                                ข้อมูลจากกล้องวงจรปิด (CCTV) และการให้บริการนอกสถานที่
                                                ซึ่งข้อมูลดังกล่าวอาจปรากฏหรือบันทึกในรูปแบบของลายลักษณ์อักษร
                                                เทปบันทึกเสียงหรือบันทึกการทำรายการ ภาพถ่ายหรือภาพเคลื่อนไหว
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                ข้อมูลทางเทคนิค ได้แก่
                                                หมายเลขประจำเครื่องคอมพิวเตอร์หรืออินเทอร์เน็ตโพรโทคอล (IP Address)
                                                ที่อยู่ Media Access Control (MAC)
                                                หมายเลขรหัสที่ติดอยู่กับอุปกรณ์ที่เชื่อมต่อกับเน็ตเวิร์กและเครือข่าย
                                                (MAC Address) ล็อก (Log) รหัสอุปกรณ์ (Device ID)
                                                ข้อมูลการเชื่อมต่อผ่านช่องทางเชื่อมต่อของแอปพลิเคชัน (API) คุกกี้
                                                (Cookies) ประเภทและเวอร์ชันของปลั๊กอิน เบราว์เซอร์
                                                ระบบปฏิบัติการและแพลตฟอร์ม
                                                ระบบอินเทอร์เน็ตหรือเครือข่ายโทรศัพท์มือถือ
                                                ข้อมูลตำแหน่งที่ตั้งทางภูมิศาสตร์ ข้อมูลการตั้งค่าในอุปกรณ์
                                                และข้อมูลทางเทคนิคอื่นๆ จากการใช้งานบนแพลตฟอร์ม
                                                แอปพลิเคชันและระบบปฏิบัติการของบริษัทฯ
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                ข้อมูลการใช้งาน ได้แก่ ชื่อหรือรหัสสำหรับการใช้บริการ (Username)
                                                รหัสผ่านสำหรับการใช้บริการ (Password) ข้อมูลเกี่ยวกับการค้นหา
                                                สถิติการเข้าดู เมนูที่ใช้งาน ระยะเวลาการใช้งานบนเว็บไซต์ แพลตฟอร์ม
                                                แอปพลิเคชัน เวลาที่คลิกครั้งสุดท้าย (Timestamp of Last Click)
                                                รายการโปรด ข้อมูลถามตอบ ข้อมูลจราจรคอมพิวเตอร์ (Log File)
                                                ข้อมูลการติดต่อบริษัทฯ
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                ข้อมูลเกี่ยวกับพฤติกรรม ได้แก่
                                                ข้อมูลเกี่ยวกับความสนใจหรือความชื่นชอบส่วนตัว
                                                ลักษณะการใช้งานหรือบริการต่างๆ
                                            </span>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <p>
                                <span  >
                                    B. ข้อมูลส่วนบุคคลที่เป็นข้อมูลอ่อนไหว ซึ่งบริษัทฯ
                                    ต้องได้รับความยินยอมจากท่านก่อนจึงจะเก็บรวบรวมได้ ได้แก่ ศาสนา
                                    ประวัติอาชญากรรม ข้อมูลสุขภาพ
                                    หรือข้อมูลอื่นใดตามที่คณะกรรมการคุ้มครองข้อมูลส่วนบุคคลกำหนด
                                </span>
                            </p>
                            <p>
                                <span  >
                                    <strong>
                                        3. วัตถุประสงค์ในการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของท่าน
                                    </strong>
                                </span>
                            </p>
                            <p>
                                <span  >
                                    บริษัทฯ จะดำเนินการเก็บรวบรวม ใช้
                                    และเปิดเผยข้อมูลส่วนบุคคลของท่านตามหลักเกณฑ์ที่กำหนดไว้ในกฎหมาย กล่าวคือ
                                </span>
                            </p>
                            <ol className="wp-block-list">
                                <li style={{ listStyleType: "none" }}>
                                    <ol className="wp-block-list">
                                        <p />
                                        <li>
                                            <span  >
                                                ความจำเป็นตามสัญญาหรือคำขอที่ท่านทำไว้แก่บริษัทฯ
                                                เพื่อให้เป็นไปตามสัญญาหรือคำขอที่ท่านทำไว้แก่บริษัทฯ
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                หน้าที่ตามกฎหมาย ซึ่งบริษัทฯ ต้องปฏิบัติตามที่กฎหมายกำหนด
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                ประโยชน์โดยชอบด้วยกฎหมายของบริษัทฯ หรือบุคคลหรือนิติบุคคลอื่น
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                ความจำเป็นในการป้องกันอันตรายต่อชีวิต ร่างกาย หรือสุขภาพของบุคคล
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                ความจำเป็นเพื่อประโยชน์สาธารณะ ภารกิจเพื่อประโยชน์สาธารณะ
                                                หรือการใช้สิทธิของภาครัฐ{" "}
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                ความยินยอมจากท่านในกรณีที่ไม่ต้องด้วยหลักเกณฑ์ตามที่ระบุในข้อ 1. ถึง
                                                5.
                                            </span>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <p>
                                <span  >
                                    ท้ังนี้ โดยมีวัตถุประสงค์อย่างหนึ่งอย่างใด ดังต่อไปนี้
                                </span>
                            </p>
                            <ol>
                                <li style={{ listStyleType: "none" }}>
                                    <ol>
                                        <li>
                                            <span  >
                                                เพื่อการติดต่อ สื่อสาร
                                                หรือให้ข้อมูลที่เกี่ยวข้องหรือเกี่ยวเนื่องกับผลิตภัณฑ์หรือบริการที่ท่านใช้หรือจะใช้กับบริษัท
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                เพื่อการดำเนินการให้เป็นไปตามข้อตกลงที่ระบุไว้ในคำขอของท่านหรือสัญญาที่ท่านได้ทำไว้กับบริษัทฯ
                                                หรือที่เกี่ยวเนื่องกับสัญญาหรือคำขอที่ท่านทำไว้กับบริษัทฯ เช่น
                                                การรับส่งเอกสาร รวมถึงเพื่อให้เป็นไปตามสัญญาที่ทำขึ้นระหว่างบริษัทฯ
                                                กับบุคคลอื่นที่จำเป็นและเกี่ยวข้องกับการให้บริการแก่ท่าน
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                เพื่อการบริหารความสัมพันธ์ระหว่างท่านกับบริษัทฯ
                                                และจัดทำรายละเอียดหรือประวัติการใช้บริการของท่านสำหรับการให้บริการในครั้งต่อๆ
                                                ไปแก่ท่าน
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                เพื่อการบริหารจัดการข้อมูลของลูกค้าที่เป็นนิติบุคคลหรือกองทรัพย์สินซึ่งอาจมีข้อมูลส่วนบุคคลของท่านด้วย
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                เพื่อการปฏิบัติตามกฎหมายและกฎเกณฑ์ต่างๆ ที่เกี่ยวข้อง
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                เพื่อการพิสูจน์และยืนยันตัวตนของท่านตามกระบวนการการรู้จักลูกค้าของบริษัทฯ
                                                รวมถึงตรวจสอบข้อมูลของท่านและติดตามการตรวจสอบตามแนวทางที่กฎหมายและบริษัทฯ
                                                กำหนด
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                เพื่อการดำเนินการใดๆ ตามที่หน่วยงานที่ทำหน้าที่กำกับดูแลบริษัทฯ
                                                กำหนด ร้องขอ หรือแนะนำให้ปฏิบัติตาม เช่น
                                                การป้องกันลูกค้าจากข้อจำกัดบางประการ
                                                การป้องกันผู้สูงอายุจากการเข้าทำธุรกรรมบางประเภทด้วยตนเอง
                                                และการควบคุมความเสียหาย
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                เพื่อการจัดการและบริหารงานภายในของบริษัทฯ เช่น การกำกับดูแล ปรับปรุง
                                                และตรวจสอบการดำเนินงานภายในของบริษัทฯ
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                เพื่อการบริหารหรือจัดการต่อความเสี่ยงต่างๆ เช่น
                                            </span>
                                            <ol>
                                                <li style={{ listStyleType: "none" }}>
                                                    <ul>
                                                        <li>
                                                            <span  >
                                                                การป้องกัน รับมือ
                                                                หรือลดความเสี่ยงที่อาจเกิดจากการกระทำที่ผิดกฎหมาย
                                                                ซึ่งอาจเกิดขึ้นกับท่าน ลูกค้าของบริษัทฯ บุคลากรของบริษัทฯ
                                                                และบริษัทฯ
                                                                โดยใช้ประกอบการพิจารณาปรับปรุงระบบรักษาความปลอดภัยเกี่ยวกับการใช้งานผ่านช่องทางต่างๆ
                                                                ระบบงานและระบบรักษาความมั่นคงปลอดภัยในการปฏิบัติงานด้านเทคโนโลยีสารสนเทศของบริษัทฯ
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span  >
                                                                การรักษาความปลอดภัย เช่น
                                                                การบันทึกภาพผู้ที่มาติดต่อหรือทำธุรกรรมกับบริษัทฯ
                                                                ผ่านกล้องวงจรปิด (CCTV)
                                                                และการแลกบัตรแสดงตนก่อนเข้าอาคารเพื่อการรักษาความปลอดภัยภายในบริเวณบริษัทฯ
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span  >
                                                                การบริหารความเสี่ยงที่เกี่ยวข้องกับการประกอบธุรกิจของสถาบันการเงิน
                                                                เช่น ความเสี่ยงด้านเครดิต ความเสี่ยงด้านปฏิบัติการ
                                                                ความเสี่ยงด้านกฎหมาย ความเสี่ยงด้านสภาพคล่อง
                                                                และความเสี่ยงด้านตลาด
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ol>
                                        </li>
                                        <li>
                                            <span  >
                                                เพื่อการจัดหาและเสนอผลิตภัณฑ์ บริการ
                                                และทางเลือกในการให้บริการแก่ท่าน รวมถึงประชาสัมพันธ์ ติดต่อสื่อสาร
                                                แจ้งเตือน มอบ หรือนำเสนอเอกสิทธิ์ สิทธิประโยชน์ รางวัล
                                                หรือข้อมูลเกี่ยวกับผลิตภัณฑ์หรือบริการของบริษัทฯ และบริษัทในกลุ่ม
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                เพื่อการจัดหาและเสนอผลิตภัณฑ์ บริการ
                                                และทางเลือกในการให้บริการแก่ท่าน รวมถึงประชาสัมพันธ์ ติดต่อสื่อสาร
                                                แจ้งเตือน มอบ หรือนำเสนอเอกสิทธิ์ สิทธิประโยชน์ รางวัล
                                                หรือข้อมูลเกี่ยวกับผลิตภัณฑ์หรือบริการของบริษัทฯ
                                                หรือพันธมิตรทางธุรกิจที่น่าจะตรงกับความต้องการของท่าน
                                                หรือเพื่อจัดกิจกรรมและรายการส่งเสริมการขาย การเข้าร่วมรายการชิงโชค
                                                หรือการจับรางวัลแก่ท่าน
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                เพื่อการตรวจสอบการใช้บริการ
                                                หรือตรวจสอบรายการดำเนินการตามคำสั่งของท่านหรือคู่สัญญาของท่าน
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                เพื่อการจัดการเกี่ยวกับการให้บริการและข้อร้องเรียนต่างๆ
                                                หรือการรับส่งข้อมูลภายในบริษัทฯ หรือระหว่างบริษัทฯ กับบุคคลอื่น
                                                หรือการดำเนินการเพื่อรองรับข้อร้องเรียนของลูกค้า การชดเชยเยียวยา
                                                หรือใช้เป็นข้อมูลประกอบการปรับกระบวนการทำงานเกี่ยวกับเรื่องดังกล่าว
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                เพื่อการวิเคราะห์เชิงสถิติหรือวิจัยที่เกี่ยวข้องกับการประกอบธุรกิจของบริษัทฯ
                                                และบริษัทในกลุ่มหรือในเครือของบริษัทฯ
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                เพื่อการปรับกลยุทธ์ รักษาผลประโยชน์
                                                หรือประเมินผลประกอบการหรือการให้บริการของบริษัทฯ
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                เพื่อการประเมิน พัฒนา และปรับปรุงผลิตภัณฑ์หรือบริการ
                                                หรือดำเนินการเพื่อบังคับตามสิทธิของบริษัทฯ และการสำรวจทางการตลาด
                                                และอาจให้ข้อมูลที่ได้จากการวิเคราะห์ดังกล่าวแก่ท่านเพื่อวางแผนทางการเงินหรือการใช้บริการต่างๆ
                                                กับบริษัทฯ ต่อไป หรือให้แก่พันธมิตรทางธุรกิจของบริษัทฯ
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                เพื่อการจัดโครงการหรือกิจกรรมส่งเสริมการขาย การประชุม สัมมนา
                                                สันทนาการ และการเยี่ยมชมสถานประกอบการ
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                เพื่อการจัดเก็บข้อมูลในคลาวด์ (Cloud Storage) และในระบบอื่นๆ
                                                ที่บริษัทฯ ใช้จัดเก็บข้อมูล
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                เพื่อการปฏิบัติหน้าที่ตามเงื่อนไขที่บริษัทเป็นคู่สัญญาหรือบังคับสิทธิตามกฎหมายหรือสัญญาที่ผูกพันบริษัทฯ
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                เพื่อเชื่อมต่อหรืออำนวยความสะดวกในการเข้าใช้งานเว็บไซต์ แอปพลิเคชัน
                                                และแพลตฟอร์มของบริษัทฯ หรือบุคคลอื่น
                                            </span>
                                            <ol className="wp-block-list"></ol>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <p>
                                <span  >
                                    ทั้งนี้ การเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลดังกล่าวของท่าน
                                    รวมถึงการส่งหรือโอนข้อมูลส่วนบุคคลของท่านไปต่างประเทศที่บริษัทฯ
                                    ดำเนินการตามหลักเกณฑ์ดังกล่าวนั้นด้วย
                                </span>
                            </p>
                            <p>
                                <span  >
                                    <strong>
                                        4. บุคคลหรือหน่วยงานที่อาจได้รับการเปิดเผยข้อมูลส่วนบุคคลของท่านจาก
                                        บริษัท ฯ<br />
                                    </strong>
                                </span>
                            </p>
                            <ol className="wp-block-list">
                                <li style={{ listStyleType: "none" }}>
                                    <ol className="wp-block-list">
                                        <p />
                                        <li>
                                            <span  >
                                                พันธมิตรทางธุรกิจของบริษัทฯ เช่น ผู้ร่วมให้บริการแพลตฟอร์ม
                                                หรือบุคคลที่มีชื่อหรือตราสัญลักษณ์ปรากฏอยู่ในสัญญา เว็บไซต์
                                                หรือช่องทางการให้บริการอื่นๆ ของบริษัทฯ
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                บุคคลที่เกี่ยวข้องกับการให้บริการของบริษัทฯ เช่น ผู้ให้บริการภายนอก
                                                (Outsource)
                                                ผู้รับจ้างดำเนินการหรือผู้ขายสินค้าหรือบริการให้แก่บริษัทฯ เช่น
                                                ผู้ให้บริการพัฒนาโครงสร้างพื้นฐาน ผู้ให้บริการเครือข่ายอินเทอร์เน็ต
                                                ผู้ให้บริการด้านโทรคมนาคมและติดต่อสื่อสาร
                                                ผู้ให้บริการโครงสร้างทางเทคนิค
                                                ผู้ให้บริการพัฒนาระบบอิเล็กทรอนิกส์หรือเทคโนโลยีสารสนเทศ
                                                ผู้ให้บริการโลจิสติกส์และคลังสินค้า ผู้ให้บริการคลาวด์ (Cloud)
                                                ผู้ให้บริการวิเคราะห์ข้อมูล ผู้ให้บริการด้านการติดต่อสื่อสาร
                                                ผู้ให้บริการสำรวจข้อมูล ผู้ให้บริการจัดงานและกิจกรรมต่างๆ
                                                ผู้ให้บริการระบบยืนยันและพิสูจน์ตัวตน
                                                ผู้ให้บริการด้านการตรวจสอบและยืนยันตัวตน บุคคลหรือหน่วยงานต่างๆ
                                                ตามที่กฎหมายกำหนด
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                บุคคลหรือหน่วยงานต่างๆ ตามที่กฎหมายกำหนด บริษัทฯ
                                                อาจต้องเปิดเผยข้อมูลส่วนบุคคลของท่านเพื่อปฏิบัติตามกฎหมาย กฎเกณฑ์
                                                ข้อบังคับต่างๆ หรือคำสั่งของหน่วยงานราชการ หน่วยงานภาครัฐ
                                                หน่วยงานกำกับดูแล หรือในกรณีที่บริษัทฯ
                                                เชื่อว่าการดำเนินการดังกล่าวจำเป็นเพื่อปฏิบัติตามกฎหมาย
                                                เพื่อปกป้องสิทธิของบริษัทฯ หรือบุคคลอื่น
                                                เพื่อความปลอดภัยของบุคคลอื่น เพื่อป้องกัน ตรวจสอบ
                                                หรือจัดการเกี่ยวกับการทุจริต ความมั่นคง หรือความปลอดภัยในด้านต่างๆ
                                            </span>
                                        </li>
                                        <li>
                                            <span  >ที่ปรึกษาด้านต่างๆ</span>
                                        </li>
                                        <li>
                                            <span  >บุคคลอื่นที่เกี่ยวข้องกับท่าน</span>
                                        </li>
                                        <li>
                                            <span  >
                                                สมาคม องค์กร ชมรม และหน่วยงานต่างๆ
                                            </span>
                                        </li>
                                        <li>
                                            <span  >เว็บไซต์และสื่อสังคมออนไลน์ต่างๆ</span>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <p>
                                <span  >
                                    <strong>
                                        5. การเก็บรักษาข้อมูลส่วนบุคคลของท่าน
                                        และระยะเวลาในการเก็บรักษาข้อมูลส่วนบุคคลของท่าน
                                    </strong>
                                </span>
                            </p>
                            <ol className="wp-block-list">
                                <li style={{ listStyleType: "none" }}>
                                    <ol className="wp-block-list">
                                        <p />
                                        <li>
                                            <span  >
                                                การเก็บรักษาข้อมูลส่วนบุคคลของท่าน&nbsp;
                                                การเก็บรักษาข้อมูลในการประมวลผลนั้น
                                                บริษัทจะจัดให้มีขั้นตอนและวิธีการที่เป็นไปตามกฎหมาย
                                                โดยคำนึงถึงสิทธิและผลกระทบที่อาจเกิดขึ้นเป็นสำคัญ ทางบริษัทฯ
                                                จะจัดให้มีมาตรการป้องกันคุ้มครองข้อมูลส่วนบุคคลทั้งที่เป็นมาตรการทางเทคโนโลยีและมาตรการทางองค์กร
                                                เพื่อจำกัดการเข้าถึงข้อมูลส่วนบุคคลที่ไม่มีความจำเป็น
                                                เพื่อป้องกันและลดความเสี่ยงในการเกิดการรั่วไหลของข้อมูล
                                                หรือการนำข้อมูลส่วนบุคคลของท่านไปใช้โดยมิชอบ บริษัทฯ
                                                กำหนดมาตรการรักษาความปลอดภัยของข้อมูลส่วนบุคคลทั้งที่อยู่ในรูปแบบเอกสารและรูปแบบอิเล็กทรอนิกส์
                                                เพื่อป้องกันการสูญหาย การเข้าถึง การใช้ การเปลี่ยนแปลง แก้ไข
                                                หรือการเปิดเผยข้อมูลส่วนบุคคลโดยไม่มีสิทธิหรือโดยไม่ชอบด้วยกฎหมาย
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                ระยะเวลาในการเก็บรักษาข้อมูลส่วนบุคคลของท่าน&nbsp; บริษัทฯ
                                                จะเก็บรวบรวมข้อมูลส่วนบุคคลของท่านตามวัตถุประสงค์ที่บริษัทฯ
                                                ได้แจ้งไว้ในหนังสือฉบับนี้ตามความจำเป็นที่กฎหมายกำหนด และสูงสุด 10
                                                ปี นับแต่วันที่ท่านสิ้นสุดความสัมพันธ์กับบริษัทฯ
                                                เว้นแต่มีความจำเป็นอื่นๆ ตามที่กฎหมายกำหนด
                                                หรือข้อมูลส่วนบุคคลดังกล่าวเป็นข้อมูลที่ไม่สามารถลบหรือทำลายได้เนื่องจากข้อจำกัดทางเทคนิค
                                            </span>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <p>
                                <span  >
                                    <strong>
                                        6. การส่งข้อมูลส่วนบุคคลของท่านไปยังต่างประเทศ
                                        <br />
                                    </strong>
                                </span>
                            </p>
                            <p>
                                <span  >
                                    กรณีที่บริษัทฯ
                                    มีความจำเป็นต้องส่งหรือโอนข้อมูลส่วนบุคคลของท่านไปยังบุคคลซึ่งอยู่ในต่างประเทศ
                                    เช่น คู่สัญญาของท่านหรือคู่สัญญาของบริษัทฯ ตัวแทนของบริษัทฯ สาขาของบริษัทฯ
                                    ที่อยู่ต่างประเทศ บริษัทในเครือของบริษัทฯ หน่วยงาน หรือองค์กรระหว่างประเทศ
                                    ประเทศปลายทางที่รับข้อมูลดังกล่าวอาจมีมาตรฐานการคุ้มครองข้อมูลส่วนบุคคลที่ไม่เพียงพอ
                                    ตามที่กฎหมายกำหนด
                                </span>
                            </p>
                            <p>
                                <span  >
                                    อย่างไรก็ตาม ในกรณีดังกล่าว บริษัทฯ
                                    จะจัดให้มีกระบวนการที่เหมาะสมเพื่อให้ท่านมั่นใจได้ว่าข้อมูลส่วนบุคคลของท่านที่ถูกส่งไปยังผู้รับข้อมูลจะปลอดภัย
                                </span>
                            </p>
                            <p>
                                <span  >
                                    <strong>7. ระบบเว็บไซต์ที่บริษัทใช้ในการเก็บรวบรวมข้อมูล</strong>
                                </span>
                            </p>
                            <p>
                                <span  >
                                    ในการเข้าใช้งานระบบเว็บไซต์ของบริษัทฯ บริษัทฯ
                                    จะเก็บรวบรวมข้อมูลบางอย่างจากการใช้งานของท่านโดยอัตโนมัติ
                                    เพื่อวัตถุประสงค์ตามที่กำหนดไว้ในหนังสือฉบับนี้ เช่น บริษัทฯ
                                    จะนำข้อมูลที่คุกกี้และเทคโนโลยีซึ่งคล้ายคลึงกันได้บันทึกหรือเก็บรวบรวมไว้
                                    ไปใช้ในการวิเคราะห์เชิงสถิติ
                                    หรือในกิจกรรมอื่นของระบบเว็บไซต์หรือกิจการของบริษัทฯ เพื่อช่วยให้บริษัทฯ
                                    สามารถมอบประสบการณ์ที่ดีในการใช้งานเว็บไซต์แก่ท่าน
                                    รวมถึงการปรับปรุงประสิทธิภาพและคุณภาพของการให้บริการระบบเว็บไซต์ของบริษัทฯ
                                    ให้ดียิ่งขึ้น
                                </span>
                            </p>
                            <p>
                                <span  >
                                    <strong>8. สิทธิของเจ้าของข้อมูลส่วนบุคคล</strong>
                                </span>
                            </p>
                            <ol className="wp-block-list">
                                <li style={{ listStyleType: "none" }}>
                                    <ol className="wp-block-list">
                                        <p />
                                        <li>
                                            <span  >
                                                <strong>สิทธิขอเข้าถึงและขอรับสำเนาข้อมูลส่วนบุคคล</strong>
                                            </span>
                                            <br />
                                            <span  >
                                                ท่านมีสิทธิขอเข้าถึง และขอรับสำเนาข้อมูลส่วนบุคคลที่เกี่ยวกับท่าน
                                                ซึ่งอยู่ในความรับผิดชอบของบริษัท หรือขอให้บริษัทฯ
                                                เปิดเผยถึงการได้มาซึ่งข้อมูลส่วนบุคคลดังกล่าวที่ท่านไม่ได้ให้ความยินยอม
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                <strong>
                                                    สิทธิขอรับหรือส่งหรือโอนข้อมูลส่วนบุคคลไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่น
                                                </strong>
                                            </span>
                                            <br />
                                            <span  >
                                                ท่านมีสิทธิขอรับข้อมูลส่วนบุคคลเกี่ยวกับท่านที่ท่านได้ให้แก่บริษัทฯ
                                                ตามความยินยอมหรือความจำเป็นตามสัญญาหรือคำขอที่ท่านได้ทำไว้แก่บริษัทฯ
                                                หรือตามที่คณะกรรมการคุ้มครองข้อมูลส่วนบุคคลประกาศกำหนด
                                                ในกรณีที่บริษัทฯ
                                                ได้ทำให้ข้อมูลส่วนบุคคลดังกล่าวอยู่ในรูปแบบที่สามารถอ่านหรือใช้งานโดยทั่วไปได้ด้วยเครื่องมือหรืออุปกรณ์ที่ทำงานได้โดยอัตโนมัติและสามารถใช้หรือเปิดเผยข้อมูลส่วนบุคคลได้ด้วยวิธีการอัตโนมัติ
                                                รวมทั้งมีสิทธิ (1) ขอให้บริษัทฯ
                                                ส่งหรือโอนข้อมูลส่วนบุคคลในรูปแบบดังกล่าวไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่นเมื่อสามารถทำได้ด้วยวิธีการอัตโนมัติ
                                                และ (2) ขอรับข้อมูลส่วนบุคคลที่บริษัทฯ
                                                ส่งหรือโอนข้อมูลส่วนบุคคลในรูปแบบดังกล่าวไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่นโดยตรง
                                                เว้นแต่โดยสภาพทางเทคนิคไม่สามารถทำได้
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                <strong>สิทธิขอคัดค้าน</strong>
                                            </span>
                                            <br />
                                            <span  >
                                                ท่านมีสิทธิขอคัดค้านการเก็บรวบรวม ใช้
                                                หรือเปิดเผยข้อมูลส่วนบุคคลของบริษัทฯ ได้ ในกรณีที่ (1) บริษัทฯ
                                                เก็บข้อมูลส่วนบุคคลของท่านเนื่องจากความจำเป็นในการปฏิบัติภารกิจเพื่อประโยชน์สาธารณะของบริษัทฯ
                                                หรือการใช้สิทธิของภาครัฐ หรือเพื่อประโยชน์โดยชอบด้วยกฎหมายของบริษัทฯ
                                                หรือบุคคลหรือนิติบุคคลอื่น (2) บริษัทฯ เก็บรวบรวม ใช้
                                                หรือเปิดเผยข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์เกี่ยวกับการตลาดแบบตรง
                                                หรือ (3) บริษัทฯ เก็บรวบรวม ใช้
                                                หรือเปิดเผยข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์เกี่ยวกับการศึกษาวิจัยทางวิทยาศาสตร์
                                                ประวัติศาสตร์ หรือสถิติ
                                                เว้นแต่เป็นการจำเป็นเพื่อดำเนินภารกิจเพื่อประโยชน์สาธารณะ
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                <strong>สิทธิขอให้ลบหรือทำลายข้อมูลส่วนบุคคล</strong>
                                            </span>
                                            <br />
                                            <span  >
                                                ท่านมีสิทธิขอให้บริษัทฯ ลบหรือทำลาย
                                                หรือทำให้ข้อมูลส่วนบุคคลของท่านเป็นข้อมูลที่ไม่สามารถระบุถึงตัวท่านได้ในกรณีที่
                                            </span>
                                            <p />
                                            <ul>
                                                <li>
                                                    <span  >
                                                        ข้อมูลส่วนบุคคลของท่านหมดความจำเป็นที่บริษัทฯ
                                                        จะต้องเก็บรักษาไว้ตามวัตถุประสงค์
                                                    </span>
                                                </li>
                                                <li>
                                                    <span  >
                                                        ท่านถอนความยินยอมและบริษัทฯ ไม่มีอำนาจตามกฎหมายที่จะเก็บรวบรวม
                                                        ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลนั้นได้ต่อไป
                                                    </span>
                                                </li>
                                                <li>
                                                    <span  >
                                                        ท่านคัดค้านการเก็บรวบรวม ใช้
                                                        หรือเปิดเผยข้อมูลส่วนบุคคลของท่านที่บริษัทฯ
                                                        เก็บรวบรวมเนื่องจากความจำเป็นในการปฏิบัติภารกิจเพื่อประโยชน์สาธารณะของบริษัทฯ
                                                        หรือการใช้สิทธิของภาครัฐ
                                                        หรือเพื่อประโยชน์โดยชอบด้วยกฎหมายของบริษัทฯ
                                                        หรือบุคคลหรือนิติบุคคลอื่น และบริษัทฯ
                                                        ไม่อาจปฏิเสธการคัดค้านดังกล่าวได้
                                                    </span>
                                                </li>
                                                <li>
                                                    <span  >
                                                        ท่านคัดค้านการเก็บรวบรวม ใช้
                                                        หรือเปิดเผยข้อมูลส่วนบุคคลเพื่อวัตถุประสงค์เกี่ยวกับการตลาดแบบตรง
                                                        หรือ ข้อมูลส่วนบุคคลของท่านได้ถูกเก็บรวบรวม ใช้
                                                        หรือเปิดเผยโดยไม่ชอบด้วยกฎหมาย
                                                    </span>
                                                </li>
                                            </ul>
                                            <p>
                                                <span  >
                                                    ทั้งนี้ เว้นแต่บริษัทฯ
                                                    จำเป็นต้องเก็บข้อมูลส่วนบุคคลของท่านเพื่อการปฏิบัติตามกฎหมาย
                                                    การก่อตั้งสิทธิเรียกร้องตามกฎหมาย
                                                    หรือการใช้หรือปกป้องสิทธิเรียกร้องของบริษัทฯ
                                                </span>
                                            </p>
                                        </li>
                                        <li>
                                            <span  >
                                                <strong>สิทธิขอให้ระงับการใช้ข้อมูลส่วนบุคคล</strong>
                                            </span>
                                            <br />
                                            <span  >
                                                ท่านมีสิทธิระงับการใช้ข้อมูลส่วนบุคคลของท่าน ในกรณีที่
                                            </span>
                                            <p />
                                            <ul>
                                                <li>
                                                    <span  >
                                                        บริษัทฯ
                                                        อยู่ระหว่างการตรวจสอบตามคำขอใช้สิทธิแก้ไขข้อมูลส่วนบุคคลให้ถูกต้องเป็นปัจจุบัน
                                                    </span>
                                                </li>
                                                <li>
                                                    <span  >
                                                        บริษัทฯ เก็บรวบรวม ใช้
                                                        หรือเปิดเผยข้อมูลส่วนบุคคลของท่านโดยไม่ชอบด้วยกฎหมาย
                                                    </span>
                                                </li>
                                                <li>
                                                    <span  >
                                                        บริษัทฯ ไม่จำเป็นต้องเก็บรวบรวม ใช้
                                                        หรือเปิดเผยข้อมูลนั้นเพื่อวัตถุประสงค์อย่างหนึ่งอย่างใดแล้ว
                                                        แต่ท่านประสงค์จะให้บริษัทฯ
                                                        เก็บข้อมูลส่วนบุคคลของท่านเพื่อประโยชน์ของท่านตามกฎหมาย หรือ
                                                        ท่านประสงค์จะให้บริษัทฯ ระงับการใช้ข้อมูลส่วนบุคคลของท่าน
                                                        เนื่องจากอยู่ในระหว่างการพิสูจน์หรือตรวจสอบคำคัดค้านข้อมูลส่วนบุคคลของท่าน
                                                    </span>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <span  >
                                                <strong>สิทธิขอแก้ไขข้อมูลส่วนบุคคลให้ถูกต้อง</strong>
                                            </span>
                                            <br />
                                            <span  >
                                                ท่านมีสิทธิขอให้บริษัทฯ แก้ไขข้อมูลส่วนบุคคลของท่านให้ถูกต้อง
                                                เป็นปัจจุบัน สมบูรณ์ และไม่ก่อให้เกิดความเข้าใจผิด
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                <strong>สิทธิในการถอนความยินยอม</strong>
                                            </span>
                                            <br />
                                            <span  >
                                                ท่านมีสิทธิถอนความยินยอมต่อบริษัทฯ ที่ท่านยินยอมให้บริษัทฯ
                                                เก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของท่านเมื่อใดก็ได้
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                <strong>สิทธิแจ้งยกเลิกความยินยอม</strong>
                                            </span>
                                            <br />
                                            <span  >
                                                ท่านมีสิทธิแจ้งยกเลิกความยินยอมสำหรับข้อมูลส่วนบุคคลที่บริษัทฯ
                                                ได้เก็บรวบรวมไว้ก่อนที่พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.
                                                2562 ใช้บังคับ
                                                ด้วยวิธีการยื่นคำขอยกเลิกความยินยอมต่อสาขาหรือหน่วยงานของบริษัทฯ
                                                ที่เป็นสาขาหรือหน่วยงานหลักที่ท่านใช้บริการหรือเคยใช้บริการอยู่
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                <strong>สิทธิร้องเรียน</strong>
                                            </span>
                                            <br />
                                            <span  >
                                                ท่านมีสิทธิร้องเรียนไปยังหน่วยงานหรือผู้มีอำนาจหน้าที่ตามกฎหมายในกรณีที่บริษัทฯ
                                                หรือผู้ประมวลผลข้อมูลส่วนบุคคล
                                                รวมทั้งลูกจ้างหรือผู้รับจ้างของบริษัทฯ
                                                หรือผู้ประมวลผลข้อมูลส่วนบุคคล
                                                ฝ่าฝืนหรือไม่ปฏิบัติตามกฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคล
                                            </span>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <p>
                                <span  >
                                    กรณีที่ท่านประสงค์จะใช้สิทธิประการใดตามข้อ 1. ถึง 9. ดังกล่าวข้างต้น
                                    ท่านสามารถทำคำขอไปยังบริษัทฯ ผ่านช่องทางสาขาของบริษัทฯ
                                    หรือช่องทางอื่นใดตามที่บริษัทฯ กำหนด เมื่อบริษัทฯ ได้รับคำขอจากท่านแล้ว
                                    บริษัทฯ จะพิจารณาคำขอของท่านตามหลักเกณฑ์และเงื่อนไขที่กฎหมายกำหนด
                                    และจะดำเนินการตามคำขอของท่านให้แล้วเสร็จและแจ้งผลการพิจารณาและการดำเนินการให้ท่านทราบภายใน
                                    30 วัน นับแต่วันที่บริษัทฯ
                                    ได้รับคำขอและเอกสารประกอบการดำเนินการตามคำขอครบถ้วนแล้ว
                                </span>
                            </p>
                            <p>
                                <span  >
                                    ในกรณีที่ท่านใช้สิทธิของเจ้าของข้อมูลส่วนบุคคลดังกล่าว
                                    อาจทำให้ท่านไม่สามารถรับบริการบางอย่างจากบริษัทฯ ในระหว่างที่บริษัทฯ
                                    พิจารณาหรือดำเนินการตามคำขอของท่านได้ บริษัทฯ
                                    จะไม่คิดค่าธรรมเนียมจากท่านอันเนื่องมาจากการขอใช้สิทธิดังกล่าว เว้นแต่
                                    หากบริษัทฯ เห็นว่าการขอใช้สิทธิของท่านเป็นการใช้สิทธิมากเกินควร
                                    หรือไม่มีเหตุอันสมควรอื่นใด บริษัทฯ
                                    อาจเรียกเก็บค่าธรรมเนียมในการดำเนินการตามคำขอของท่านตามอัตราที่บริษัทฯ
                                    ประกาศกำหนด
                                </span>
                            </p>
                            <p>
                                <span  >
                                    ทั้งนี้ ท่านสามารถขอใช้สิทธิของเจ้าของข้อมูลส่วนบุคคลได้
                                    นับตั้งแต่วันที่พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
                                    ใช้บังคับเป็นต้นไป
                                </span>
                            </p>
                            <p>
                                <span  >
                                    <strong>
                                        9. การเปลี่ยนแปลงหนังสือแจ้งการคุ้มครองข้อมูลส่วนบุคคลฉบับนี้
                                        <br />
                                    </strong>
                                </span>
                            </p>
                            <p>
                                <span  >
                                    เนื่องจากในปัจจุบันยังไม่มีการออกกฎหมายชัดเจนเกี่ยวกับเนื้อหาในหนังสือคุ้มครองข้อมูลส่วนบุคคล
                                    นโยบายฉบับนี้อาจมีการปรับปรุงให้เหมาะสมและสอดคล้องกับสถานการณ์และตามการให้บริการจริง
                                    โดยบริษัทฯ จะมีการแจ้งประกาศที่มีการปรับปรุงใหม่บนเว็บไซต์นี้ ดังนั้น
                                    บริษัทฯ
                                    ขอแนะนำให้ท่านตรวจสอบให้แน่ใจว่าท่านได้เข้าใจการเปลี่ยนแปลงตามข้อกำหนดดังกล่าว
                                </span>
                            </p>
                            <p>
                                <span  >
                                    <strong>
                                        10. ข้อมูลการติดต่อ
                                        <br />
                                    </strong>
                                </span>
                            </p>
                            <p>
                                <span  >
                                    หากท่านมีความประสงค์ที่จะติดต่อ
                                    หรือต้องการทราบข้อมูลเพิ่มเติมหรือคำอธิบายเกี่ยวกับการเก็บรวบรวม ใช้
                                    และเปิดเผยข้อมูลส่วนบุคคลของท่าน
                                    รวมถึงการขอใช้สิทธิของเจ้าของข้อมูลส่วนบุคคลตามหนังสือฉบับนี้
                                    ท่านสามารถติดต่อบริษัทฯ ได้ที่&nbsp;{" "}
                                    <strong>
                                        37/1 ถนนทะเลหลวง ตำบลบ่อยาง อำเภอเมืองสงขลา จ.สงขลา 90000
                                    </strong>
                                </span>
                            </p>
                            <p>
                                <span  >
                                    นอกจากนี้ท่านสามารถติดต่อเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล
                                    หรือหน่วยงานคุ้มครองข้อมูลส่วนบุคคลได้ทางโทรศัพท์
                                    : <strong>074 314 888</strong>{" "}
                                    หรือที่สำนักงานใหญ่ 37/1 ถนนทะเลหลวง ตำบลบ่อยาง อำเภอเมืองสงขลา จ.สงขลา 90000
                                </span>
                            </p>
                            <p>
                                <span  >
                                    <strong>11. นิยามและขอบเขต</strong>
                                </span>
                            </p>
                            <p>
                                <span  >
                                    เพื่อให้เกิดความเข้าใจที่ถูกต้องในทุกฝ่าย ทางบริษัทฯ
                                    ขออธิบายนิยามของคำที่เกี่ยวข้องไว้ดังนี้
                                    โดยนิยามดังกล่าวล้วนอ้างอิงมาจากพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.
                                    2562
                                </span>
                            </p>
                            <ol>
                                <li style={{ listStyleType: "none" }}>
                                    <ol>
                                        <li>
                                            <span  >
                                                ข้อมูลส่วนบุคคล (Personal Data):
                                                หมายถึงข้อมูลเกี่ยวกับบุคคลซึ่งสามารถทำให้ระบุตัวตนถึงบุคคลนั้นได้
                                                ไม่ว่าทางตรงหรือทางอ้อม ไม่รวมถึงข้อมูลของผู้ถึงแก่กรรม
                                                และข้อมูลของนิติบุคคล
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                ข้อมูลอ่อนไหว (Sensitive Data):
                                                หมายถึงข้อมูลส่วนบุคคลที่มีความละเอียดอ่อน เป็นเรื่องส่วนตัว
                                                และมีโอกาสสูงซึ่งจะถูกใช้ในการเลือกปฏิบัติอย่างไม่เป็นธรรม
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                การประมวลผลข้อมูลส่วนบุคคล: หมายถึงการดำเนินการอย่างใด ๆ
                                                กับข้อมูลส่วนบุคคล อาทิ การเก็บรวบรวม การโอน
                                                การใช้ข้อมูลเพื่อการวิจัยกลุ่มเป้าหมายของบริษัทฯ
                                                การใช้ข้อมูลสำหรับการตลาดแบบตรง การโอนข้อมูล รวมไปถึงการลบ
                                                การทำลายข้อมูล
                                                หรือการทำให้ข้อมูลดังกล่าวไม่อาจใช้ในการระบุตัวบุคคลได้
                                                (Anonymization)
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                การลบข้อมูล: หมายถึงการลบ ทำลาย หรือกระทำด้วยประการใด ๆ
                                                ให้ข้อมูลนั้นออกจากระบบโดยไม่อาจกู้คืนได้ ทั้งนี้ไม่ว่าในเวลาใด ๆ
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                การโอนข้อมูล: หมายถึงการโอนข้อมูลส่วนบุคคลไปยังองค์กรอื่น
                                                ทั้งที่เป็นการโอนภายในประเทศและการโอนข้อมูลระหว่างประเทศ
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                การรั่วไหลของข้อมูลส่วนบุคคล:
                                                หมายถึงการรั่วไหลหรือละเมิดความปลอดภัยของข้อมูลส่วนบุคคล
                                                ซึ่งอาจก่อให้เกิดผลกระทบต่อสิทธิ หรือก่อให้เกิดความเสียหาย สูญหาย
                                                เปลี่ยนแปลง เปิดเผยโดยมิได้รับอนุญาต
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                ผู้ประมวลผลข้อมูลส่วนบุคคล:
                                                หมายถึงบุคคลซึ่งมีหน้าที่ในการประมวลผลข้อมูลตามคำสั่งหรือข้อสัญญาซึ่งได้ทำขึ้นระหว่างผู้ประมวลผลข้อมูลส่วนบุคคลและผู้ควบคุมข้อมูลส่วนบุคคล
                                                รวมไปถึงตัวแทนของผู้ประมวลผลข้อมูลอีกทอดหนึ่งด้วย
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                ผู้ควบคุมข้อมูลส่วนบุคคล:
                                                หมายถึงบุคคลซึ่งมีอำนาจตัดสินใจเกี่ยวกับการประมวลผลข้อมูลส่วนบุคคล
                                                รวมถึงตัวแทนของผู้ควบคุมข้อมูลส่วนบุคคลด้วย
                                            </span>
                                        </li>
                                        <li>
                                            <span  >
                                                บริษัทฯ: ในที่นี้หมายถึง บริษัท ห้างเพชรทองรัตนมณี จำกัด
                                                ซึ่งอาจมีฐานะเป็นทั้งผู้ประมวลผลข้อมูลส่วนบุคคลและผู้ควบคุมข้อมูลส่วนบุคคล
                                            </span>
                                        </li>
                                    </ol>
                                </li>
                            </ol>


                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}