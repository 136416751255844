import React from "react";

export default function Contactus() {
    return (
        <>
            <h4>ติดต่อเราได้ที่: </h4>
            <p><span>🏠 </span>37/1 ถนนทะเลหลวง ตำบลบ่อยาง อำเภอเมืองสงขลา จ.สงขลา 90000</p>
            <p><span>📍 </span>สงขลา ประเทศไทย</p>
            <p><span>📞 </span>074 314 888</p>
        </>
    );
}