import React from "react";

export default function Aboutus() {
  return (
    <>
      <h4>ห้างทองอรวรา : Onvara Gold Shop</h4>
      <p>- จำหน่าย-รับทำ เพชรแท้ ทองคำรูปพรรณ 96.5% จากเยาวราชทุกชิ้น มาตรฐาน สคบ. ทองใหม่ทุกชิ้น -</p>
    </>
  );
}
